import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { initialState as app } from '../providers/NotificationProvider/_BLL/reducer';
import { initialState as users } from '../../features/users/_BLL/reducer';
import { initialState as searchCompanies } from '../../features/users/_BLL/searchCompanies/reducer';
import { loadState, saveState } from './localStorage';
import { rootReducer } from './rootReducer';
import rootSaga from './rootSaga';

const sagaMiddleware = createSagaMiddleware();

const persistedState = loadState();

export const store = configureStore({
	reducer: rootReducer,
	preloadedState: persistedState,
	middleware: getDefaultMiddleware => getDefaultMiddleware().prepend(sagaMiddleware),
});

store.subscribe(() => {
	saveState({
		app,
		auth: store.getState().auth,
		users,
		searchCompanies,
	});
});

sagaMiddleware.run(rootSaga);
