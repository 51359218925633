import React, { memo } from 'react';
import s from './ButtonRounded.module.scss';
import { Button, ButtonProps } from '@mui/material';
import { Preloader } from '../../loaders/Preloader';
import { RequestStatus } from 'src/shared/api/types';
import cn from 'classnames';

interface Props extends ButtonProps {
	status?: RequestStatus;
	active?: boolean;
}

export const ButtonRounded: React.FC<Props> = memo(props => {
	const {
		className, //
		status,
		active,
		disabled,
		children,
		color = 'primary',
		...buttonProps
	} = props;

	const renderLoader = () => {
		return status === RequestStatus.loading ? <Preloader style={{ width: '1rem', height: '1rem' }} /> : '';
	};

	const isDisabled = () => {
		if (status !== undefined) {
			return status === RequestStatus.loading || disabled;
		} else {
			return disabled;
		}
	};

	// * Render
	return (
		<Button
			{...buttonProps}
			className={cn(s.button, className)}
			disabled={isDisabled()}
			variant={active ? 'contained' : 'outlined'}
			color={color}
		>
			<span className={s.inner}>
				{children}
				{renderLoader()}
			</span>
		</Button>
	);
});
