import {NotificationType} from './types';

export enum AppAT {
  // Global error
  CLEAR_ERROR = 'app/CLEAR_ERROR',
  SET_ERROR = 'app/SET_ERROR',
  // Notifications
  ADD_NOTIFICATION = 'app/ADD_NOTIFICATION_SUCCESS',
  DELETE_NOTIFICATION = 'app/DELETE_NOTIFICATION'
}

export const appAction = {
	clearError: () => (
		{
			type: AppAT.CLEAR_ERROR as const
		}
	),
	setError: (message: string, incidentId: string) => (
		{
			type: AppAT.SET_ERROR as const,
			payload: {
				message,
				incidentId
			}
		}
	),
	addNotification: (type: NotificationType, message: string) => (
		{
			type: AppAT.ADD_NOTIFICATION as const,
			payload: {
				type,
				message
			}
		}
	),
	deleteNotification: (id: string) => (
		{
			type: AppAT.DELETE_NOTIFICATION as const,
			payload: {
				id
			}
		}
	)
};

export type AppAction = ReturnType<typeof appAction.clearError>
  | ReturnType<typeof appAction.setError>
  | ReturnType<typeof appAction.addNotification>
  | ReturnType<typeof appAction.deleteNotification>
