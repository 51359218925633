import { FormControl, FormHelperText, InputLabel, Select as MUISelect, SelectProps } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';

interface SelectItem {
	id: number | string;
	name: string;
}

interface Props extends SelectProps {
	label?: string;
	errorMessage?: string;
	options: SelectItem[];
}

export const Select: React.FC<Props> = props => {

	const {
		label,
		errorMessage,
		options,
		...selectProps // MUI select props
	} = props;

	// * Render
	return (
		<FormControl fullWidth size={selectProps.size}>
			<InputLabel id={label}>{label}</InputLabel>
			<MUISelect
				labelId={label}
				label={label}
				value={selectProps.value}
				onChange={selectProps.onChange}
			>
				{!selectProps.multiple && (
					<MenuItem
						value={''}
					>
						Unknown
					</MenuItem>
				)}
				{options.map(option => (
					<MenuItem
						key={option.id}
						value={option.id}
					>
						{option.name}
					</MenuItem>
				))}
			</MUISelect>

			<FormHelperText>{errorMessage}</FormHelperText>
		</FormControl>
	);
};