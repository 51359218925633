import { TextFieldProps } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { PasswordInput } from '../inputs/PasswordInput';

type Props = TextFieldProps & {
	label?: string;
	name: string;
};

export const PasswordField: React.FC<Props> = props => {
	const { control } = useFormContext();

	const { label, name, ...restProps } = props;

	return (
		<Controller
			name={name}
			control={control}
			render={({ field, fieldState }) => (
				<PasswordInput
					{...restProps}
					{...field}
					size="small"
					label={label ? label : name}
					error={!!fieldState.error?.message}
					helperText={fieldState.error?.message}
				/>
			)}
		/>
	);
};
