import { Checkbox, FormControlLabel, FormLabel } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { SpecialAccessDatapoint } from '../../auth/_BLL/types';
import s from './AccessDataPoints.module.scss';
import { ButtonRounded } from 'src/shared/ui/buttons/ButtonRounded/ButtonRounded';

interface Props {
	label: string;
	fieldName: string;
	dictionary: SpecialAccessDatapoint[];
}

export const AccessDataPoints: React.FC<Props> = props => {

	const {
		label,
		fieldName,
		dictionary
	} = props;

	const {control, setValue} = useFormContext();

	const handleChange = (datapointId: number, activeDatapointIds: number[]) => {
		const accessIdIsActive = activeDatapointIds.includes(datapointId);

		if (accessIdIsActive) {
			setValue(fieldName, activeDatapointIds.filter(id => id !== datapointId));
		} else {
			setValue(fieldName, [...activeDatapointIds, datapointId]);
		}
	};

	const allDatapointIds = dictionary.map(dictionaryItem => dictionaryItem.datapointId);

	const selectAll = () => {
		setValue(fieldName, allDatapointIds);
	};

	// * Render
	return (
		<Controller
			name={fieldName}
			control={control}
			render={({field}) => (
				<FormControl className={s.formControl}>
					<FormLabel>{label}</FormLabel>

					<ButtonRounded
						onClick={selectAll}
					>
						Select all
					</ButtonRounded>

					{dictionary
						.map(dictionaryItem => (
							<FormControlLabel
								key={dictionaryItem.datapointId}
								control={
									<Checkbox
										checked={field.value.includes(dictionaryItem.datapointId)}
										onChange={() => handleChange(dictionaryItem.datapointId, field.value)}
									/>
								}
								label={dictionaryItem.propertyDescription}
							/>
						))}
				</FormControl>
			)}
		/>
	);
};
