import { Checkbox, FormControl, FormControlLabel, FormLabel } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { keyToTitle } from '../../../helpers/stringHelpers';
import { AccessRightsGoldDICT, AccessRightsManagementDICT, AccessRightsSilverDICT } from '../../auth/_BLL/types';
import s from './AccessRightsField.module.scss';
import { ButtonRounded } from 'src/shared/ui/buttons/ButtonRounded/ButtonRounded';

interface Props {
	label: string;
	fieldName: string;
	dictionary: AccessRightsManagementDICT | AccessRightsSilverDICT | AccessRightsGoldDICT;
}

export const AccessRightsField: React.FC<Props> = props => {

	const {
		label,
		fieldName,
		dictionary
	} = props;

	const {control, setValue} = useFormContext();

	const handleChange = (accessId: number, activeAccessIds: number[]) => {

		const accessIdIsActive = activeAccessIds.includes(accessId);

		if (accessIdIsActive) {
			setValue(fieldName, activeAccessIds.filter(id => id !== accessId));
		} else {
			setValue(fieldName, [...activeAccessIds, accessId]);
		}
	};

	const selectAll = () => {
		setValue(fieldName, Object.keys(dictionary).map(key => +key).slice(1));
	};

	const keyToLabel = (accessName: string) => {
		if (accessName === 'WHFAccess') {
			return 'WHF access';
		}

		if (accessName === 'FQAccess') {
			return 'FQ access';
		}

		return keyToTitle(accessName);
	};

	return (
		<Controller
			name={fieldName}
			control={control}
			render={({field}) => (
				<FormControl className={s.formControl}>
					<FormLabel>{label}</FormLabel>

					<ButtonRounded
						onClick={selectAll}
					>
						Select all
					</ButtonRounded>

					{Object.entries(dictionary)
						.slice(1) // ? Ignore the backend utility option (No Access).
						.map(([accessId, accessName]) => (
							<FormControlLabel
								key={accessId}
								control={
									<Checkbox
										checked={field.value.includes(+accessId)}
										onChange={() => handleChange(+accessId, field.value)}
									/>
								}
								label={keyToLabel(accessName)}
							/>
						))}
				</FormControl>
			)}
		/>
	);
};
