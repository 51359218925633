import { put } from 'redux-saga/effects';
import { createAction } from '../app/redux/createAction';
import { authAction } from '../features/auth/_BLL/actions';
import { appAction } from '../app/providers/NotificationProvider/_BLL/actions';

export function* standardError(responseError: any, stopLoadingAction: any, errorArg?: any): any {
	const { severity, message, incidentId } = responseError;

	if (severity === 'Information') {
		yield put(appAction.addNotification('info', message));
	}

	if (severity === 'Warning') {
		yield put(appAction.addNotification('warning', message));
	}

	if (severity === 'Error') {
		yield put(appAction.addNotification('error', message));
	}

	if (severity === 'AccessDenied') {
		yield put(appAction.addNotification('error', message));
		yield put(authAction.logout());
	}

	if (severity === 'Critical') {
		yield put(appAction.setError(message, incidentId));
	}

	yield stopLoadingAction && put(createAction(stopLoadingAction, errorArg));
}
