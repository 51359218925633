import { LinearProgress, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React, { HTMLAttributes, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { FixedSizeList } from 'react-window';
import { useActions } from '../../../../../app/redux/createAction';
import { RootState } from '../../../../../app/redux/rootReducer';
import { useOnclickAwayHandler } from '../../../../../hooks/useOnclickAwayHandler';
import { usePrevious } from '../../../../../hooks/usePrevious';
import { searchCompaniesAction } from '../../../_BLL/searchCompanies/actions';
import s from './SearchBar.module.scss';
import { SearchBarResult } from '../searchBarResult/SearchBarResult';
import { ButtonRounded } from 'src/shared/ui/buttons/ButtonRounded/ButtonRounded';
import { RequestStatus } from 'src/shared/api/types';

export const SearchBar: React.FC<HTMLAttributes<HTMLDivElement>> = divProps => {
	// * Selectors
	const { search, pagination, companies, status } = useSelector((state: RootState) => state.searchCompanies);

	const { pageIndex, pageSize } = pagination;

	// * Actions
	const { saveSearch, searchRequest, clearResults, clearAll, paginationIncrement } = useActions(searchCompaniesAction);

	// * History API
	const prevSearch = usePrevious(search);
	const prevPageIndex = usePrevious(pageIndex);

	const searchZone = useRef(null);
	useOnclickAwayHandler(searchZone, clearAll);

	useEffect(() => {
		clearAll();
	}, [clearAll]);

	// * Component logic
	useEffect(() => {
		const searchChange = search !== prevSearch;
		const pageNumberChange = pageIndex !== prevPageIndex;

		const body = {
			searchPrefix: search,
			pagination: {
				pageSize,
				pageIndex,
			},
		};

		if (searchChange || pageNumberChange) {
			if (!search) clearResults();

			if (prevSearch !== undefined) {
				const url = 'companiesLookup';

				if (searchChange && search.trim() !== '' && search) {
					searchRequest(url, body);
					clearResults();
				}

				if (pageNumberChange && search.trim() !== '' && search) {
					searchRequest(url, body);
				}
			}
		}
	}, [search, pageIndex, companies, prevSearch, prevPageIndex, pageSize, clearAll, clearResults, searchRequest]);

	return (
		<div
			{...divProps}
			ref={searchZone}
		>
			{/* Search bar */}
			<div className={s.inputContainer}>
				<TextField
					className={s.textField}
					type="text"
					size="small"
					name="search"
					placeholder="Search..."
					autoComplete="off"
					variant="outlined"
					value={search}
					onChange={event => saveSearch(event.target.value)}
				/>
				{status === RequestStatus.loading && <LinearProgress className={s.linearProgress} />}
			</div>

			{/* Search results */}
			{companies.length > 0 ? (
				<div className={s.searchResults}>
					<FixedSizeList
						className={s.fixedSizeList}
						itemData={companies}
						height={300}
						width={'100%'}
						itemSize={30}
						itemCount={companies.length}
					>
						{props => (
							<SearchBarResult
								{...props}
								data={companies[props.index]}
							/>
						)}
					</FixedSizeList>

					{status !== RequestStatus.loading ? (
						<ButtonRounded
							className={s.button}
							onClick={paginationIncrement}
						>
							SHOW MORE
						</ButtonRounded>
					) : (
						<CircularProgress
							style={{ marginLeft: '40px' }}
							size={30}
						/>
					)}
				</div>
			) : null}
		</div>
	);
};
