import {FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput} from '@mui/material';
import React, {MouseEvent, useState} from 'react';
import {Visibility, VisibilityOff} from '@mui/icons-material';


export const PasswordInput: React.FC<any> = props => {

	const {
		variant,
		size,
		error,
		helperText,
		...restProps
	} = props;

	const [showPassword, setShowPassword] = useState<boolean>(false);
	const handleClickShowPassword = () => setShowPassword(!showPassword);
	const handleMouseDownPassword = (e: MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
	};

	return (
		<FormControl
			variant={variant}
			error={error}
			size={size}
		>
			<InputLabel
				htmlFor="password"
			>
				Password
			</InputLabel>
			<OutlinedInput
				{...restProps}
				type={showPassword ? 'text' : 'password'}
				endAdornment={
					<InputAdornment position="end">
						<IconButton
							aria-label="toggle password visibility"
							onClick={handleClickShowPassword}
							onMouseDown={handleMouseDownPassword}
							edge="end"
						>
							{showPassword ? <Visibility/> : <VisibilityOff/>}
						</IconButton>
					</InputAdornment>
				}
				labelWidth={70}
			/>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
		</FormControl>
	);
};
