import {v4} from 'uuid';
import {AppAction, AppAT} from './actions';
import {ErrorCritical, NotificationItem} from './types';

export const initialState: State = {
	error: null,
	notifications: []
};

export const reducer = (state: State = initialState, action: AppAction): State => {

	switch (action.type) {

	case AppAT.CLEAR_ERROR: {
		return {
			...state,
			error: null
		};
	}

	case AppAT.SET_ERROR: {
		const {message, incidentId} = action.payload;

		return {
			...state,
			error: {message, incidentId}
		};
	}

	case AppAT.ADD_NOTIFICATION: {
		const {type, message} = action.payload;

		return {
			...state,
			notifications: [...state.notifications, {id: v4(), type, message}]
		};
	}

	case AppAT.DELETE_NOTIFICATION: {
		const {id} = action.payload;

		return {
			...state,
			notifications: state.notifications.filter(note => note.id !== id)
		};
	}

	default:
		return state;
	}
};

// Types
type State = {
  error: ErrorCritical | null
  notifications: Array<NotificationItem>
}
