import { TextFieldProps } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Select } from '../inputs/Select';

type Props = TextFieldProps & {
	label: string;
	name: string;
	validate?: any;
	options: Option[];
};

interface Option {
	id: number | string;
	name: string;
}

export const SelectField: React.FC<Props> = props => {
	const { label, name, validate, options } = props;

	const { control } = useFormContext();

	return (
		<Controller
			name={name}
			control={control}
			render={({ field, fieldState }) => (
				<Select
					{...field}
					label={label}
					size="small"
					variant="outlined"
					options={options}
					errorMessage={fieldState.error?.message}
				/>
			)}
		/>
	);
};
