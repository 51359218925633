import React, {ChangeEvent} from 'react';
import {TextField, TextFieldProps} from '@mui/material';


type Props = TextFieldProps & {
	value: string;
	onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

// TODO: Replace all string fields with this component.
export const StringInput: React.FC<Props> = props => {

	return (
		<TextField
			{...props}
		/>
	);
};
