import { combineReducers } from 'redux';
import { reducer as app } from '../providers/NotificationProvider/_BLL/reducer';
import { reducer as auth } from '../../features/auth/_BLL/reducer';
import { reducer as users } from '../../features/users/_BLL/reducer';
import { reducer as searchCompanies } from '../../features/users/_BLL/searchCompanies/reducer';

export const rootReducer = combineReducers({
	app,
	auth,
	users,
	searchCompanies,
});

export type RootState = ReturnType<typeof rootReducer>;
