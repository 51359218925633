import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../../app/redux/rootReducer';
import { Logo } from '../../../../shared/ui/Logo';
import { RouterPath } from '../../../../app/router/AppRouter';
import { HeaderProfile } from '../HeaderProfile/HeaderProfile';
import s from './Header.module.scss';

export const Header: React.FC = () => {
	const navigate = useNavigate();

	// Selectors
	const { userData, status } = useSelector((state: RootState) => state.auth);

	// Functions
	const onLogoClick = () => navigate(RouterPath.mainPage);

	return (
		<header className={s.container}>
			<div className={s.left}>
				<Logo
					className={s.logo}
					onClick={onLogoClick}
				/>
			</div>

			{userData && <HeaderProfile userData={userData} />}
		</header>
	);
};
