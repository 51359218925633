import {call, delay, put, takeLatest} from 'redux-saga/effects';
import {Actions, ActionType, searchCompaniesAction} from './actions';
import {CompanyBaseInfo, PaginationType} from './types';
import {standardError} from '../../../../helpers/sagaHelpers';
import {postRequest} from '../../../../app/api/api';
import { RequestStatus } from 'src/shared/api/types';

// Workers
export function* search(action: any): any {
	const {url, body} = action.payload;
	yield delay(500);

	try {
		yield put(searchCompaniesAction.load());
		const res = yield call(postRequest, url, body);
		yield put(searchCompaniesAction.saveCompanies(res.companies, res.pagination));
		yield put(searchCompaniesAction.success());

	} catch (error) {
		yield standardError(error, ActionType.FAIL);
	}
}

// Watchers
export function* watcher() {
	yield takeLatest(ActionType.SEARCH, search);
}

// Reducer
interface State {
	companies: Array<CompanyBaseInfo> | []
	pagination: PaginationType,
	search: string,
	status: RequestStatus
}

export const initialState: State = {
	search: '', // Search current value
	companies: [], // Search result for companies
	pagination: {
		pageSize: 10,
		pageIndex: 0,
		pageCount: 0,
	},
	status: RequestStatus.still
};

export function reducer(state: State = initialState, action: Actions): State {

	switch (action.type) {
		case ActionType.LOAD: {
			return {
				...state,
				status: RequestStatus.loading
			};
		}

		case ActionType.SUCCESS: {
			return {
				...state,
				status: RequestStatus.still
			};
		}

		case ActionType.FAIL: {
			return {
				...state,
				status: RequestStatus.still
			};
		}

		case ActionType.SAVE_COMPANIES: {
			const {companies, pagination} = action.payload;

			return {

				...state,
				companies: [...state.companies, ...companies],
				pagination: {
					...state.pagination,
					...pagination
				},
			};
		}

		case ActionType.SAVE_SEARCH: {
			const {search} = action.payload;

			return {
				...state,
				search,
			};
		}

		case ActionType.LOOKUP_CLEAR_ALL: {
			return {
				...state,
				search: '',
				companies: [],
				pagination: {
					...state.pagination,
					pageSize: 10,
					pageIndex: 0,
					pageCount: 0,
				}
			};
		}

		case ActionType.CLEAR_RESULTS: {
			return {
				...state,
				companies: [],
				pagination: {
					...state.pagination,
					pageSize: 10,
					pageIndex: 0,
					pageCount: 0,
				}
			};
		}

		case ActionType.PAGINATION_INCREMENT: {
			const {pageIndex} = state.pagination;
			return {
				...state,
				pagination: {
					...state.pagination,
					pageIndex: pageIndex + 1,
				}
			};
		}

		default:
			return state;
	}
}
