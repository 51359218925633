import {Dictionaries, SignInValues, UserData} from './types';

export enum AuthActionType {
  SIGN_IN = 'auth/SIGN_IN',
  SIGN_IN_SUCCESS = 'auth/SIGN_IN_SUCCESS',
  SIGN_IN_FAIL = 'auth/SIGN_IN_FAIL',
  SAVE_DICTIONARIES = 'auth/SAVE_DICTIONARIES',
  LOGOUT = 'auth/LOGOUT',
}

export const authAction = {
	signIn: (signInValues: SignInValues) => (
		{
			type: AuthActionType.SIGN_IN as const,
			payload: {
				signInValues
			}
		}),
	signInSuccess: (userData: UserData) => (
		{
			type: AuthActionType.SIGN_IN_SUCCESS as const,
			payload: {
				userData,
			}
		}),
	signInFail: () => (
		{
			type: AuthActionType.SIGN_IN_FAIL as const
		}),
	saveDictionaries: (dictionaries: Dictionaries) => (
		{
			type: AuthActionType.SAVE_DICTIONARIES as const,
			payload: {
				dictionaries
			}
		}
	),
	logout: () => (
		{
			type: AuthActionType.LOGOUT as const
		}
	),
};

export type AuthAction = ReturnType<typeof authAction.signIn>
  | ReturnType<typeof authAction.signInSuccess>
  | ReturnType<typeof authAction.signInFail>
  | ReturnType<typeof authAction.saveDictionaries>
  | ReturnType<typeof authAction.logout>
