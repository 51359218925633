import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../app/redux/rootReducer';
import { Select } from '../../../shared/ui/inputs/Select';
import s from './UserSearch.module.scss';

interface Props {
	filterUsers: (userName: string, groupName: string) => void;
}

export const UserSearch: React.FC<Props> = props => {
	const { filterUsers } = props;

	const users = useSelector((state: RootState) => state.users.users);
	const userGroups = useSelector((state: RootState) => state.users.userGroups);

	const [userName, setUserName] = useState('');
	const [groupName, setGroupName] = useState('');

	useEffect(() => {
		filterUsers(userName, groupName);
	}, [userName, groupName]);

	useEffect(() => {
		// reset filter when users change
		setUserName('');
		setGroupName('');
	}, [users]);

	return (
		<div className={s.container}>
			<TextField
				size="small"
				label="filter by login"
				value={userName}
				onChange={e => setUserName(e.currentTarget.value)}
			/>

			<Select
				size="small"
				label="filter by group name"
				value={groupName}
				onChange={(event: any) => setGroupName(event.target.value)}
				options={userGroups.map(group => ({
					id: group,
					name: group,
				}))}
			/>
		</div>
	);
};
