import React, { memo } from 'react';
import s from './ButtonsSquared.module.scss';
import { Button, ButtonProps } from '@mui/material';
import { Preloader } from '../../loaders/Preloader';
import { RequestStatus } from 'src/shared/api/types';

interface Props extends ButtonProps {
	status?: RequestStatus;
}

export const ButtonSquared: React.FC<Props> = memo(props => {
	const { className, status, disabled, children, color = 'primary', ...buttonProps } = props;

	const renderLoader = () => {
		return status === RequestStatus.loading ? <Preloader style={{ width: '1rem', height: '1rem' }} /> : '';
	};

	const isDisabled = () => {
		if (status !== undefined) {
			return status === RequestStatus.loading || disabled;
		} else {
			return disabled;
		}
	};

	return (
		<Button
			{...buttonProps}
			className={className}
			disabled={isDisabled()}
			variant="contained"
			color={color}
		>
			<span className={s.inner}>
				{children}
				{renderLoader()}
			</span>
		</Button>
	);
});
