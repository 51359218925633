import React from 'react';
import { SingleUser } from '../_BLL/types';
import s from './UsersListItem.module.scss';

interface Props {
	user: SingleUser;
	activateEdit: (user: SingleUser) => void;
}

export const UsersListItem: React.FC<Props> = props => {

	const {
		activateEdit,
		user
	} = props;

	return (
		<div className={s.container}
			onClick={() => activateEdit(user)}
		>
			<span className={s.id}>User ID: <b>{user.id}</b></span>
			<span className={s.name}>Login: <b>{user.username}</b></span>
			<span className={s.name}>User name: <b>{user.displayName}</b></span>
			<span className={s.name}>Group name: <b>{user.groupname}</b></span>
		</div>
	);
};
