import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/rootReducer';
import { appAction } from '../../_BLL/actions';
import { Modal } from 'src/shared/ui/Modal';
import { CriticalErrorModalContent } from '../CriticalErrorModalContent/CriticalErrorModalContent';
import { NotificationProvider } from './Notification/Notification';
import { useActions } from '../../../../redux/createAction';

export const NotificationsProvider = () => {
	const { error, notifications } = useSelector((state: RootState) => state.app);

	const { clearError } = useActions(appAction);

	return (
		<>
			<Modal
				isOpen={!!error}
				onClose={clearError}
			>
				<CriticalErrorModalContent error={error} />
			</Modal>

			<NotificationProvider notifications={notifications} />
		</>
	);
};
