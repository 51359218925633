import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { GreetingsPage } from '../../features/auth/GreetingsPage/GreetingsPage';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/rootReducer';
import { Users } from '../../features/users/Users/Users';

export enum RouterPath {
	mainPage = '/main',
}

export const AppRouter = () => {
	const { dictionaries, userData } = useSelector((state: RootState) => state.auth);

	return (
		<BrowserRouter>
			<Routes>
				<Route
					path="/"
					element={
						userData && userData.accessRights.includes('UserManagement') && dictionaries ? (
							<Navigate
								to={RouterPath.mainPage}
								replace
							/>
						) : (
							<GreetingsPage />
						)
					}
				/>

				<Route
					path={RouterPath.mainPage}
					element={
						userData && userData.accessRights.includes('UserManagement') && dictionaries ? (
							<Users />
						) : (
							<Navigate
								to="/"
								replace
							/>
						)
					}
				/>

				<Route element={<div>404</div>} />
			</Routes>
		</BrowserRouter>
	);
};
