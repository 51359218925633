import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { CopperCompany } from '../_BLL/types';
import s from './CompaniesList.module.scss';

interface Props {
	title: string;
}

export const CompaniesList: React.FC<Props> = props => {

	const {
		title,
	} = props;

	// * Form
	const {control, setValue} = useFormContext();

	const getCompanies = (fieldValue: CopperCompany[]) => {
		return fieldValue.filter(company => company.accessType === (title.includes('Advanced') ? 1 : 2));
	};

	const removeCompany = (formValueCompanies: CopperCompany[], companyToRemove: CopperCompany) => {
		const remainingCompanies = formValueCompanies.filter(company => !(company.id === companyToRemove.id && company.accessType === companyToRemove.accessType));

		setValue('copperCompanies', remainingCompanies);
	};

	return (
		<Controller
			control={control}
			name="copperCompanies"
			render={({field}) => (<>
				<h3 className={s.title}>{title}</h3>

				{field.value.length > 0
					?
					<ul className={s.list}>
						{getCompanies(field.value).map(company => (
							<li 
								className={s.list__item}
								key={company.id}
							>
								<div>id: <b>{company.id}</b></div>
								<div>name: <b>{company.displayName ? company.displayName : 'Unnamed'}</b></div>
									{/* <RemoveIcon className={s.remove} onClick={() => removeCompany(field.value, company)}/> */}
							</li>
						))}
					</ul>
					:
					<span>No Companies</span>
				}
			</>)}
		/>
	);
};