export enum ActionType {
  LOAD = 'searchCompanies/LOAD',
  SUCCESS = 'searchCompanies/SUCCESS',
  FAIL = 'searchCompanies/FAIL',
  SEARCH = 'searchCompanies/SEARCH',
  SAVE_COMPANIES = 'searchCompanies/SEARCH_SUCCESS',
  SAVE_SEARCH = 'searchCompanies/SAVE_SEARCH',
  LOOKUP_CLEAR_ALL = 'searchCompanies/LOOKUP_CLEAR_ALL',
  CLEAR_RESULTS = 'searchCompanies/CLEAR_RESULTS',
  PAGINATION_INCREMENT = 'searchCompanies/PAGINATION_INCREMENT',
}

export const searchCompaniesAction = {
	load: () => ({
		type: ActionType.LOAD as const
	}),
	success: () => ({
		type: ActionType.SUCCESS as const
	}),
	fail: () => ({
		type: ActionType.FAIL as const
	}),
	searchRequest: (url: string, body: any) => ({
		type: ActionType.SEARCH as const,
		payload: {
			url,
			body
		}
	}),
	saveCompanies: (companies: any, pagination: any) => ({
		type: ActionType.SAVE_COMPANIES as const,
		payload: {
			companies,
			pagination
		}
	}),
	saveSearch: (search: string) => ({
		type: ActionType.SAVE_SEARCH as const,
		payload: {
			search
		}
	}),
	clearAll: () => ({
		type: ActionType.LOOKUP_CLEAR_ALL as const
	}),
	clearResults: () => ({
		type: ActionType.CLEAR_RESULTS as const
	}),
	paginationIncrement: () => ({
		type: ActionType.PAGINATION_INCREMENT as const
	}),
};

export type Actions = ReturnType<typeof searchCompaniesAction.load>
  | ReturnType<typeof searchCompaniesAction.success>
  | ReturnType<typeof searchCompaniesAction.fail>
  | ReturnType<typeof searchCompaniesAction.searchRequest>
  | ReturnType<typeof searchCompaniesAction.saveCompanies>
  | ReturnType<typeof searchCompaniesAction.saveSearch>
  | ReturnType<typeof searchCompaniesAction.clearAll>
  | ReturnType<typeof searchCompaniesAction.clearResults>
  | ReturnType<typeof searchCompaniesAction.paginationIncrement>
