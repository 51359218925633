import { LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useActions } from '../../../app/redux/createAction';
import { RootState } from '../../../app/redux/rootReducer';
import { Modal } from 'src/shared/ui/Modal';
import { Header } from 'src/entities/Header';
import { UserForm } from '../UserForm/UserForm';
import { UserSearch } from '../UserSearch/UserSearch';
import { UsersListItem } from '../UsersListItem/UsersListItem';
import { usersACTION } from '../_BLL/actions';
import { SingleUser } from '../_BLL/types';
import s from './Users.module.scss';
import { ButtonRounded } from 'src/shared/ui/buttons/ButtonRounded/ButtonRounded';
import { RequestStatus } from 'src/shared/api/types';

export const Users: React.FC = () => {
	const users = useSelector((state: RootState) => state.users.users);
	const status = useSelector((state: RootState) => state.users.status);
	const { getUsers, addOrEditUser, getUserGroups } = useActions(usersACTION);

	useEffect(() => {
		getUsers();
		getUserGroups();
	}, []);

	// * Modal state
	const modalInitialState: { show: boolean; userData: null | SingleUser } = {
		show: false,
		userData: null,
	};

	const [modal, setModal] = useState(modalInitialState);

	const toggleModal = () => {
		setModal(modalInitialState);
	};

	const activateEdit = (user: SingleUser) => {
		setModal({
			show: true,
			userData: user,
		});
	};

	const addOrEditUserHandler = (user: SingleUser) => {
		addOrEditUser(user);
	};

	// ! HARDCODE - User parameters can change in the future, requiring update of this object.
	const initialNewUserFormValues: any = {
		id: -1,
		username: '',
		displayName: '',
		password: null,
		maxPageSize: 5000,
		maxExportSize: 10000,
		goldAccessRights: [],
		silverAccessRights: [],
		managementAccessRights: [],
		specialAccessDatapoints: [],
		goldSpecialAccessDatapoints: [],
		copperCompanies: [],
		groupname: 'Others',
		email: null,
	};

	// * Users filter
	const [filteredUsers, setFilteredUsers] = useState(users);

	const filterUsers = (userName: string, groupName: string) => {
		if (users === null) {
			// Ignore filter
		} else {
			const filteredUsers = users
				.filter(user => user.username.toLowerCase().includes(userName.toLowerCase().trim()))
				.filter(user => (groupName === '' ? true : user.groupname.toLowerCase() === groupName.toLowerCase()));
			filteredUsers.length > 0 ? setFilteredUsers(filteredUsers) : setFilteredUsers(null);
		}
	};

	useEffect(() => {
		// reset filter when users change
		setFilteredUsers(users);
	}, [users]);

	return (
		<>
			{modal.userData && (
				<Modal
					isOpen={modal.show}
					onClose={toggleModal}
				>
					<UserForm
						user={modal.userData}
						addOrEditUser={addOrEditUserHandler}
						toggleModal={toggleModal}
					/>
				</Modal>
			)}

			<Header />

			<div className={s.container}>
				<ButtonRounded
					className={s.button}
					onClick={() => activateEdit(initialNewUserFormValues)}
				>
					Add new user
				</ButtonRounded>

				<UserSearch filterUsers={filterUsers} />

				<div className={s.users}>
					{status === RequestStatus.loading ? (
						<LinearProgress />
					) : (
						filteredUsers &&
						filteredUsers.map(user => (
							<UsersListItem
								key={user.id}
								user={user}
								activateEdit={activateEdit}
							/>
						))
					)}
				</div>
			</div>
		</>
	);
};
