import { all, spawn } from 'redux-saga/effects';
import { authWatcher } from '../../features/auth/_BLL/reducer';
import { watcher as users } from '../../features/users/_BLL/reducer';
import { watcher as searchCompanies } from '../../features/users/_BLL/searchCompanies/reducer';

export default function* rootSaga() {
	yield all([
		spawn(authWatcher),
		spawn(users),
		spawn(searchCompanies),
	]);
}
