import React from 'react';
import { NotificationItem } from '../../../_BLL/types';
import { NotificationSingle } from '../NotificationSingle/NotificationSingle';
import s from './Notification.module.scss';

interface Props {
	notifications: Array<NotificationItem>;
}

export const NotificationProvider: React.FC<Props> = props => {
	const { notifications } = props;

	return (
		<div className={s.container}>
			{notifications.map(note => (
				<NotificationSingle
					key={note.id}
					id={note.id}
					message={note.message}
					type={note.type}
				/>
			))}
		</div>
	);
};
