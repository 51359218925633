import {SingleUser} from './types';

export enum UsersAT {
	LOAD = 'users/LOAD', // Activate loading status.
	SUCCESS = 'users/SUCCESS', // Disable loading status.
	FAIL = 'users/FAIL', // Set status as failed.

	GET_USERS = 'users/GET_USERS', // Request users list.
	ADD_OR_EDIT_USER = 'users/ADD_OR_EDIT_USER', // Post changes made to user.
	DELETE_USER = 'users/DELETE_USER', // Delete user.

	STORE_SINGLE_USER = 'user/STORE_SINGLE_USER', // Add or modify single user to state.
	STORE_USERS = 'user/STORE_USERS', // Save array of users to state.
	UN_STORE_USER = 'user/UN_STORE_USER', // Remove user from state.

	GET_USER_GROUPS = 'user/GET_USER_GROUPS',
	STORE_USER_GROUPS = 'user/STORE_USER_GROUPS'
}

export const usersACTION = {
	load: () => ({
		type: UsersAT.LOAD as const
	}),
	success: () => ({
		type: UsersAT.SUCCESS as const
	}),
	fail: () => ({
		type: UsersAT.FAIL as const
	}),

	getUsers: () => ({
		type: UsersAT.GET_USERS as const
	}),
	addOrEditUser: (user: SingleUser) => ({
		type: UsersAT.ADD_OR_EDIT_USER as const,
		payload: {
			user
		}
	}),
	deleteUser: (userId: number) => ({
		type: UsersAT.DELETE_USER as const,
		payload: {
			userId
		}
	}),

	storeSingleUser: (user: SingleUser) => ({
		type: UsersAT.STORE_SINGLE_USER as const,
		payload: {
			user
		}
	}),
	storeUsers: (users: SingleUser[]) => ({
		type: UsersAT.STORE_USERS as const,
		payload: {
			users
		}
	}),
	unStoreUser: (userId: number) => ({
		type: UsersAT.UN_STORE_USER as const,
		payload: {
			userId
		}
	}),

	getUserGroups: () => ({
		type: UsersAT.GET_USER_GROUPS as const,
	}),
	storeUserGroups: (userGroups: string[]) => ({
		type: UsersAT.STORE_USER_GROUPS as const,
		payload: {
			userGroups
		}
	})
};

export type Action =
	ReturnType<typeof usersACTION.load>
	| ReturnType<typeof usersACTION.success>
	| ReturnType<typeof usersACTION.fail>

	| ReturnType<typeof usersACTION.getUsers>
	| ReturnType<typeof usersACTION.addOrEditUser>
	| ReturnType<typeof usersACTION.deleteUser>

	| ReturnType<typeof usersACTION.storeSingleUser>
	| ReturnType<typeof usersACTION.storeUsers>
	| ReturnType<typeof usersACTION.unStoreUser>

	| ReturnType<typeof usersACTION.getUserGroups>
	| ReturnType<typeof usersACTION.storeUserGroups>
