import { State } from '../../features/auth/_BLL/reducer';
import { localStorageName } from '../redux/localStorage';
import { RootState } from '../redux/rootReducer';

// Prod - iryvcwhxhg
// Dev - uxn71jhrb1
// Special - dr3t6su5bd
const host = process.env.REACT_APP_HOST;

// TODO: Type the class properly.
class ResponseError extends Error {
	severity: ErrorSeverity;
	incidentId?: string;

	constructor(message: string, severity: ErrorSeverity, incidentId?: string, ...params: any) {
		super(...params);
		this.severity = severity;
		this.message = message;
		this.incidentId = incidentId;
	}
}

const handleResponse = (parsed: any) => {
	const { severity, incidentId } = parsed;

	if (severity as ErrorSeverity) {
		if (severity === 'Information') throw new ResponseError(parsed.message, severity);
		if (severity === 'Warning') throw new ResponseError(parsed.message, severity);
		if (severity === 'Error') throw new ResponseError(parsed.message, severity);
		if (severity === 'AccessDenied') throw new ResponseError(parsed.message, severity);
		if (severity === 'Critical') throw new ResponseError(parsed.message, severity, incidentId);
	}
};

const getAuth = (): State | null => {
	const state = localStorage.getItem(localStorageName);
	const parsed = state ? (JSON.parse(state) as RootState) : null;
	return parsed ? parsed.auth : null;
};

const getHeaders = (auth: State | null) => {
	const token = auth && auth.userData ? auth.userData.token : '';

	return {
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Request-Headers': '*',
		'x-api-token': token,
	};
};

const get = async (url: string, params?: string, host?: string) => {
	const auth = getAuth();

	return await fetch(`${host}/${url}${params ? '?' + params : ''}`, {
		method: 'GET',
		headers: getHeaders(auth),
		referrerPolicy: 'no-referrer',
	});
};

const post = async <T>(url: string, data: T, host?: string) => {
	const auth = getAuth();

	return await fetch(`${host}/${url}`, {
		method: 'POST',
		mode: 'cors',
		headers: getHeaders(auth),
		referrerPolicy: 'no-referrer',
		body: JSON.stringify(data),
	});
};

const del = async (url: string, params: any, host?: string) => {
	const auth = getAuth();

	return await fetch(`${host}/${url}${params ? '?' + params : ''}`, {
		method: 'DELETE',
		headers: getHeaders(auth),
		referrerPolicy: 'no-referrer',
	});
};

export async function getRequest(url: string, params?: string) {
	const response = await get(url, params, host);
	const parsed = await response.json();

	handleResponse(parsed);

	return parsed;
}

export async function deleteRequest(url: string, params: any) {
	const response = await del(url, params, host);
	const parsed = await response.json();

	handleResponse(parsed);

	return parsed;
}

// Backend expects some POST queries to have both POST and GET style parameters
export async function postRequest(url: string, data: any, params?: string) {
	const fullUrl = url + (params ? '?' + params : '');

	const response = await post(fullUrl, data, host);

	const parsed = await response.json();

	handleResponse(parsed);

	return parsed;
}

// Types
export type SignInBody = {
	username: string;
	password: string;
};

export type ErrorSeverity = 'Information' | 'Warning' | 'Error' | 'AccessDenied' | 'Critical';
