import {call, put, takeLatest} from 'redux-saga/effects';
import { getRequest, postRequest} from '../../../app/api/api';
import {standardError} from '../../../helpers/sagaHelpers';
import {authAction, AuthAction, AuthActionType} from './actions';
import {Dictionaries, UserData} from './types';
import { RequestStatus, UrlAPI } from 'src/shared/api/types';

// Workers
export function* signIn(action: ReturnType<typeof authAction.signIn>): any {

	try {
		const userData = yield call(postRequest, UrlAPI.login, action.payload.signInValues);
		yield put(authAction.signInSuccess(userData));
		const dictionaries = yield call(getRequest, UrlAPI.dictionaries);
		yield put(authAction.saveDictionaries(dictionaries));

	} catch (error) {
		yield standardError(error, AuthActionType.SIGN_IN_FAIL);
	}
}

// Watchers
export function* authWatcher() {
	yield takeLatest(AuthActionType.SIGN_IN, signIn);
}

// Types
export interface State {
  userData: UserData | null
  dictionaries: Dictionaries | null
  registrationStep: number
  status: RequestStatus
}

const initialState: State = {
	userData: null,
	dictionaries: null,
	registrationStep: 0,
	status: RequestStatus.still,
};

export function reducer(state: State = initialState, action: AuthAction): State {

	switch (action.type) {
	case AuthActionType.SIGN_IN: {
		return {
			...state,
			status: RequestStatus.loading,
		};
	}

	case AuthActionType.SIGN_IN_SUCCESS: {
		const {payload} = action;

		return {
			...state,
			userData: payload.userData,
		};
	}

	case AuthActionType.SIGN_IN_FAIL: {
		return {
			...state,
			status: RequestStatus.still,
		};
	}

	case AuthActionType.SAVE_DICTIONARIES: {
		const {payload} = action;

		return {
			...state,
			dictionaries: payload.dictionaries,
			status: RequestStatus.still,
		};
	}

	case AuthActionType.LOGOUT: {

		return {
			...state,
			userData: null,
			status: RequestStatus.still,
			dictionaries: null,
		};
	}

	default:
		return state;
	}
}
