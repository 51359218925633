import { ThemeProvider } from '@mui/material';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { ReactElement } from 'react';
import { Provider } from 'react-redux';
import { NotificationsProvider } from 'src/app/providers/NotificationProvider';
import { theme } from 'src/shared/styles/themes/theme';
import { store } from './redux/store';
import { AppRouter } from './router/AppRouter';

export const appDecorator = (Component: ReactElement<any, any>) => (
	<ThemeProvider theme={theme}>
		<StyledEngineProvider injectFirst>
			<Provider store={store}>{Component}</Provider>
		</StyledEngineProvider>
	</ThemeProvider>
);

export const App = () => {
	return (
		<>
			{appDecorator(
				<>
					<NotificationsProvider />
					<AppRouter />
				</>,
			)}
		</>
	);
};
