import {Action, usersACTION, UsersAT} from './actions';
import {deleteRequest, getRequest, postRequest} from '../../../app/api/api';
import {call, put, takeLatest} from 'redux-saga/effects';
import {standardError} from '../../../helpers/sagaHelpers';
import {SingleUser} from './types';
import { RequestStatus } from 'src/shared/api/types';

// * Workers
export function* getUsersWorker(): any {

	try {
		yield put(usersACTION.load());
		const resUsers: SingleUser[] = yield call(getRequest, 'users');
		yield put(usersACTION.storeUsers(resUsers));
		yield put(usersACTION.success());

	} catch (error) {
		yield standardError(error, UsersAT.FAIL);
	}
}

export function* editOrUpdateUserWorker(action: ReturnType<typeof usersACTION.addOrEditUser>): any {
	// if user's id in the body is > 0, then edit user, else add user.
	const {user} = action.payload;

	try {
		yield put(usersACTION.load());
		const resUser: SingleUser = yield call(postRequest, 'users', user);
		yield put(usersACTION.storeSingleUser(resUser));
		const userGroups = yield call(getRequest, 'users/groups');
		yield put(usersACTION.storeUserGroups(userGroups));
		yield put(usersACTION.success());

	} catch (error) {
		yield standardError(error, UsersAT.FAIL);
	}
}

export function* deleteUserWorker(action: ReturnType<typeof usersACTION.deleteUser>): any {
	const {userId} = action.payload;

	try {
		yield put(usersACTION.load());
		const resDeletedUser: { id: number } = yield call(deleteRequest, 'users', `id=${userId}`);
		yield put(usersACTION.unStoreUser(resDeletedUser.id));
		yield put(usersACTION.success());

	} catch (error) {
		yield standardError(error, UsersAT.FAIL);
	}
}

export function* getUserGroupsWorker(): any {
	try {
		yield put(usersACTION.load());
		const userGroups = yield call(getRequest, 'users/groups');
		yield put(usersACTION.storeUserGroups(userGroups));
		yield put(usersACTION.success());
	} catch (error) {
		yield standardError(error, UsersAT.FAIL);
	}
}

// * Watchers
export function* watcher() {
	yield takeLatest(UsersAT.GET_USERS, getUsersWorker);
	yield takeLatest(UsersAT.ADD_OR_EDIT_USER, editOrUpdateUserWorker);
	yield takeLatest(UsersAT.DELETE_USER, deleteUserWorker);
	yield takeLatest(UsersAT.GET_USER_GROUPS, getUserGroupsWorker);
}

// * Reducer
interface State {
	users: SingleUser[] | null;
	userGroups: string[];
	status: RequestStatus;
}

export const initialState: State = {
	users: null,
	userGroups: [],
	status: RequestStatus.still
};

export function reducer(state: State = initialState, action: Action): State {

	switch (action.type) {

		case UsersAT.LOAD: {
			return {
				...state,
				status: RequestStatus.loading
			};
		}

		case UsersAT.SUCCESS: {
			return {
				...state,
				status: RequestStatus.still
			};
		}

		case UsersAT.FAIL: {
			return {
				...state,
				status: RequestStatus.failed
			};
		}

		case UsersAT.STORE_SINGLE_USER: {
			const user = action.payload.user;

			// If users don't exist, add the user.
			if (state.users === null || state.users.length === 0) {
				return {
					...state,
					users: [user]
				};
			} else {
				// If user exists replace it with updated user, else add user to users.
				const userExists = !!state.users.find(stateUser => stateUser.id === user.id);
				if (userExists) {
					return {
						...state,
						users: state.users.map(stateUser => (
							stateUser.id === user.id ? user : stateUser
						))
					};
				} else {
					return {
						...state,
						users: [...state.users, user]
					};
				}
			}
		}

		case UsersAT.STORE_USERS: {
			const users = action.payload.users;

			return {
				...state,
				users
			};
		}

		case UsersAT.UN_STORE_USER: {
			const userId = action.payload.userId;

			if (state.users === null || state.users.length === 0) {
				return state;
			} else {
				return {
					...state,
					users: state.users.filter(stateUser => stateUser.id !== userId)
				};
			}
		}

		case UsersAT.STORE_USER_GROUPS: {
			const userGroups = action.payload.userGroups;

			return {
				...state,
				userGroups
			};
		}

		default:
			return state;
	}
}
