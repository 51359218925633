import Button from '@mui/material/Button';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ListChildComponentProps } from 'react-window';
import { CompanyBaseInfo } from '../../../_BLL/searchCompanies/types';
import { AccessType, CopperCompany } from '../../../_BLL/types';
import s from './SearchBarResult.module.scss';

export const SearchBarResult: React.FC<ListChildComponentProps<CompanyBaseInfo>> = props => {

	const {
		index, // Indexes of react-window
		style, // Default styles of react-window
		data, // Single company
	} = props;

	const {watch, setValue} = useFormContext();

	const copperCompanies: CopperCompany[] = watch('copperCompanies');

	const onClick = (accessType: AccessType) => {
		if (!copperCompanies.find(company => company.id === data.id && company.accessType === accessType)) {
			setValue('copperCompanies',[...copperCompanies, {
				id: data.id,
				displayName: data.name,
				accessType: accessType
			}]);
		}
	};

	return (
		<div
			className={s.container}
			style={style}
			key={index}
		>
			<span className={s.inner}>
				<span className={s.company}><b>{data.id}:</b> {data.name}</span>

				<div>
					<Button
						onClick={() => onClick(1)}
					>
						Advanced
					</Button>

					<Button
						onClick={() => onClick(2)}
					>
						Certified
					</Button>
				</div>
			</span>
		</div>
	);
};
