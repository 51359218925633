import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { useActions } from '../../../app/redux/createAction';
import { RootState } from '../../../app/redux/rootReducer';
import { PasswordField } from '../../../shared/ui/form_fields/PasswordField';
import { SelectField } from '../../../shared/ui/form_fields/SelectField';
import { StringField } from '../../../shared/ui/form_fields/StringField';
import { keyToTitle } from '../../../helpers/stringHelpers';
import { AccessDataPoints } from '../AccessDataPoints/AccessDataPoints';
import { AccessRightsField } from '../AccessRightsField/AccessRightsField';
import { CompaniesList } from '../CompaniesList/CompaniesList';
import { SearchBar } from '../SearchBar';
import { usersACTION } from '../_BLL/actions';
import { SingleUser } from '../_BLL/types';
import s from './UserForm.module.scss';
import { ButtonRounded } from 'src/shared/ui/buttons/ButtonRounded/ButtonRounded';
import { ButtonSquared } from 'src/shared/ui/buttons/ButtonsSquared/ButtonSquared';

interface Props {
	user: SingleUser;
	addOrEditUser: (user: SingleUser) => void;
	toggleModal: () => void;
}

export const UserForm: React.FC<Props> = props => {
	const { user, addOrEditUser, toggleModal } = props;

	// * Conditions
	const newUser = user.id === -1; // ? User id -1 is a default value for new user

	// * Selectors
	const accessRightsDICT = useSelector((state: RootState) => state.auth.dictionaries?.accessRights);
	const silverSpecialAccessDatapoints = useSelector((state: RootState) => state.auth.dictionaries?.silverSpecialAccessDatapoints);
	const goldSpecialAccessDatapoints = useSelector((state: RootState) => state.auth.dictionaries?.goldSpecialAccessDatapoints);
	const groupList = useSelector((state: RootState) => state.users.userGroups);

	// * Actions
	const { deleteUser } = useActions(usersACTION);

	const handleDelete = (userId: number) => {
		deleteUser(userId);
		toggleModal();
	};

	// * Delete logic
	const [deleteConfirmation, setDeleteConfirmation] = useState(false);

	// * Form
	const schema = yup.object().shape({
		username: yup.string().required('Login is required'),
		displayName: yup.string().required('User name is required'),
		// ? For the new users the password is required, for the existed users is not.
		password: newUser ? yup.string().nullable().required('Password is required') : yup.string().nullable(),
		email: yup.string().email().required('Email is required'),
	});

	const userFormMethods = useForm({
		defaultValues: {
			...user,
		},
		resolver: yupResolver(schema),
	});

	const { handleSubmit } = userFormMethods;

	const onSubmit = (user: SingleUser) => {
		addOrEditUser(user);
		toggleModal();
	};

	// * Component
	return (
		<>
			{accessRightsDICT && goldSpecialAccessDatapoints && silverSpecialAccessDatapoints && (
				<FormProvider {...userFormMethods}>
					<form
						className={s.form}
						onSubmit={handleSubmit(onSubmit)}
					>
						{!newUser && <div className={s.caption}>User id: {user.id}</div>}

						<div className={s.row}>
							<div className={s.column}>
								<h2>User info</h2>
								<div className={s.row}>
									<StringField
										label="User name"
										name="displayName"
									/>
									<StringField
										label="login"
										name="username"
									/>
									<StringField
										label="email"
										name="email"
									/>
									<PasswordField
										label={newUser ? 'Password' : 'New password'}
										name="password"
									/>
								</div>

								<h2>User group</h2>
								<div className={s.row}>
									<SelectField
										label="Group name select"
										name="groupname"
										options={groupList.map(group => ({
											id: group,
											name: group,
										}))}
									/>

									<StringField
										label="Group name"
										name="groupname"
									/>
								</div>

								<h2>Gold excel download limit</h2>
								<div className={s.row}>
									<StringField
										label="Single file rows limit"
										name="maxPageSize"
										type="number"
									/>
									<StringField
										label="Total rows limit"
										name="maxExportSize"
										type="number"
									/>
								</div>

								<h2>Access rights</h2>
								<div className={s.row}>
									<AccessRightsField
										label={keyToTitle('goldAccessRights')}
										fieldName="goldAccessRights"
										dictionary={accessRightsDICT.Gold}
									/>
									<AccessRightsField
										label={keyToTitle('silverAccessRights')}
										fieldName="silverAccessRights"
										dictionary={accessRightsDICT.Silver}
									/>
									<AccessRightsField
										label={keyToTitle('managementAccessRights')}
										fieldName="managementAccessRights"
										dictionary={accessRightsDICT.Management}
									/>
								</div>

								<h2>Special Datapoint Access rights</h2>
								<div className={s.row}>
									<AccessDataPoints
										label={keyToTitle('goldSpecialAccessDatapoints')}
										fieldName="goldSpecialAccessDatapoints"
										dictionary={goldSpecialAccessDatapoints}
									/>

									<AccessDataPoints
										label={keyToTitle('specialAccessDatapoints')}
										fieldName="specialAccessDatapoints"
										dictionary={silverSpecialAccessDatapoints}
									/>
								</div>
							</div>

							{/* Copper companies */}
							<div className={s.column}>
								<h2>Copper companies</h2>
								<SearchBar className={s.searchBar} />

								<div className={s.row}>
									<div className={s.column}>
										<CompaniesList title="Advanced companies" />
									</div>

									<div className={s.column}>
										<CompaniesList title="Certified companies" />
									</div>
								</div>
							</div>
						</div>

						<div className={s.buttons}>
							<ButtonRounded type="submit">Save</ButtonRounded>

							{!newUser && !deleteConfirmation && <ButtonRounded onClick={() => setDeleteConfirmation(true)}>Delete user</ButtonRounded>}
							{deleteConfirmation && (
								<div className={s.paper}>
									<div className={s.caption}>Are you sure you want to delete {user.username}?</div>

									<div className={s.buttons__inner}>
										<ButtonRounded onClick={() => setDeleteConfirmation(false)}>Cancel</ButtonRounded>

										<ButtonSquared onClick={() => handleDelete(user.id)}>Delete {user.username}</ButtonSquared>
									</div>
								</div>
							)}
						</div>
					</form>
				</FormProvider>
			)}
		</>
	);
};
