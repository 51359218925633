import React, { useState } from 'react';
import { useActions } from '../../../../app/redux/createAction';
import { authAction } from '../../../../features/auth/_BLL/actions';
import { UserData } from '../../../../features/auth/_BLL/types';
import s from './HeaderProfile.module.scss';
import { ButtonRounded } from 'src/shared/ui/buttons/ButtonRounded/ButtonRounded';

interface Props {
	userData: UserData;
}

export const HeaderProfile: React.FC<Props> = props => {
	const { userData } = props;

	// Actions
	const { logout } = useActions(authAction);

	const [open, setOpen] = useState<boolean>(false);

	return (
		<div className={s.container}>
			<span
				className={s.userName}
				onClick={() => setOpen(!open)}
			>
				{'displayName' in userData ? userData.displayName : 'User'}
			</span>

			{open && (
				<div className={s.menu}>
					<ButtonRounded onClick={logout}>Logout</ButtonRounded>
				</div>
			)}
		</div>
	);
};
