import {ActionCreatorsMapObject, bindActionCreators} from 'redux';
import {useDispatch} from 'react-redux';
import {useMemo} from 'react';

export const createAction = <T extends string, P>(type: T, payload?: P): Action<T, P> => {
	return {type, payload};
};

export const useActions = <T extends ActionCreatorsMapObject>(actions: T) => {
	const dispatch = useDispatch();

	return useMemo(() => {
		return bindActionCreators(actions, dispatch);
	}, [actions, dispatch]);
};

// Types
export type Action<T, P> = {
  type: T
  payload?: P
}
