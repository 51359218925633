import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useActions } from '../../../app/redux/createAction';
import { RootState } from '../../../app/redux/rootReducer';
import { SignInBody } from '../../../app/api/api';
import { PasswordInput } from '../../../shared/ui/inputs/PasswordInput';
import { authAction } from '../_BLL/actions';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { RequestStatus } from 'src/shared/api/types';
import { ButtonSquared } from 'src/shared/ui/buttons/ButtonsSquared/ButtonSquared';
import { StringInput } from '../../../shared/ui/inputs/StringInput';
import s from './SignInForm.module.scss';
import { Preloader } from 'src/shared/ui/loaders/Preloader';

export const SignInForm = () => {
	// * Selectors
	const { status } = useSelector((state: RootState) => state.auth);

	// * Actions
	const { signIn } = useActions(authAction);

	const schema = Yup.object().shape({
		username: Yup.string().required(),
		password: Yup.string().required(),
	});

	const { handleSubmit, control } = useForm({
		defaultValues: {
			username: '',
			password: '',
		},
		resolver: yupResolver(schema),
	});

	const onSubmit = (values: SignInBody) => {
		signIn(values);
	};

	return (
		<form
			className={s.form}
			onSubmit={handleSubmit(onSubmit)}
		>
			<Controller
				name="username"
				control={control}
				render={({ field, fieldState }) => (
					<StringInput
						{...field}
						variant="outlined"
						label="Username"
						size="small"
						error={!!fieldState.error?.message}
						helperText={fieldState.error?.message}
					/>
				)}
			/>
			<Controller
				name="password"
				control={control}
				render={({ field, fieldState }) => (
					<PasswordInput
						{...field}
						label="Password"
						variant="outlined"
						size="small"
						error={!!fieldState.error?.message}
						helperText={fieldState.error?.message}
					/>
				)}
			/>

			{status === RequestStatus.loading ? (
				<div className={s.preloader}>
					<Preloader />
				</div>
			) : (
				<ButtonSquared type="submit">Sign in</ButtonSquared>
			)}
		</form>
	);
};
